import { useRouter } from 'next/router';
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { RootState } from '../../store/store';
import {
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getCookie,
  getCookieExpiryTime,
  getEnrollmentId,
} from '../../lib/utils/enrollmentUtil';
import * as CdpService from '../../services/CdpService';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { post } from '../../lib/utils/axiosFetcher';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { setCookie } from 'cookies-next';
import { JOURNEY_STATUS } from '../../../src/constants';
import { verifyMyProfileEmail } from '../../lib/utils/axiosCDPFetcher';
import { setAuthentication } from '../../features/authSlice';
import { resetProfileData } from '../../features/crProfileSlice';
import { useDispatch } from 'react-redux';
import { sendWelcomeSms } from '../../components/shared/Sms/helper';
import { store } from '../../store/store';
import { getDialingCodeByISOCode } from '../../lib/utils/dialingCodeList';

const SESSION_TOKEN = 'myProfileVerificationToken';

const useUpdateCurrentJourney = (route: any, isCDPScriptLoaded: boolean): any => {
  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  const selectedLanguage = useSelector((state: RootState) => state.selectedLanguage);
  const dispatch = useDispatch();

  const { sitecoreContext } = useSitecoreContext();
  const { site } = sitecoreContext;
  const ClinicalResearch = 'ClinicalResearch';

  const siteSettings: any = sitecoreContext?.siteSettings;
  const isSendEmailEnabled = siteSettings?.enableEmailSendFeature?.value;
  const isSendSmsEnabled = siteSettings?.enableSmsSendFeature?.value;
  const transactionalFromNumber = siteSettings?.transactionalFromNumber?.value;
  const pinPointProjectId = siteSettings?.pinPointProjectId?.value;
  const smsStudyName = siteSettings?.studyName?.value;
  const smsSponsor = siteSettings?.smsSponsor?.value;
  const sourceIdentifier = siteSettings?.smsSource?.fields?.name?.value;
  const countryCode = siteSettings?.countryCode?.value;
  const smsRootPath = sitecoreContext?.smsRootPath;
  const sendEmailEvent = process.env.NEXT_PUBLIC_CDP_SENDEMAIL_EVENT as string;
  const reduxState = store.getState();
  //const sourceApp = siteSettings?.source?.fields?.name?.value;
  const isJourneyStatusUpdated = useRef<boolean>(false);

  const cdpState = useSelector((state: RootState) => state.cdpData);
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const updatePatientOutcomeDetails = async (isSuccessPage: boolean) => {
    if (
      potentialParticipantDetailsFromStore?.potentialId ||
      (sitecoreContext as any)?.CDP?.potentialId
    ) {
      const { potentialId } = potentialParticipantDetailsFromStore;
      const { patientReferralStatus } = cdpState;
      const payload: any = {
        potentialId: potentialId || (sitecoreContext as any)?.CDP?.potentialId,
        patientReferralStatus: isSuccessPage ? 'New' : patientReferralStatus,
        journeyStatus: JOURNEY_STATUS.COMPLETED,
      };
      payload.deActivateExistingEnrollment = true;

      const { data: response } = await post(`/Patient/UpdatePatientOutcomeDetails`, payload);
      return response;
    }
  };

  const { mutate: updatePatientOutcomes } = useMutation(
    'update-patient-outcome-details',
    updatePatientOutcomeDetails,
    {
      onSuccess: () => undefined,
      onError: () => undefined,
    }
  );

  const [isLoaded, setIsLoaded] = useState(false);
  const router = useRouter();

  const verifySessionToken = async (verificationToken: string): Promise<boolean> => {
    const { data } = await verifyMyProfileEmail(`/api/contacts/VerifyToken`, {
      verificationToken: verificationToken,
    });
    return data;
  };

  const verifyToken = async () => {
    try {
      const sessionToken = sessionStorage.getItem(SESSION_TOKEN);
      if (sessionToken && !isAuthenticated) {
        const isTokenVerified = await verifySessionToken(sessionToken);
        if (isTokenVerified) {
          dispatch(setAuthentication(isTokenVerified));
          return true;
        } else {
          dispatch(setAuthentication(false));
          dispatch(resetProfileData());
          sessionStorage.removeItem(SESSION_TOKEN);
          return false;
        }
      }
      return false;
    } catch (error) {
      dispatch(setAuthentication(false));
      return false;
    }
  };
  const resetCookies = (isTokenVerified) => {
    const enrollmentId = getCookie('enrollmentID');
    if (enrollmentId) {
      sessionStorage.setItem('enrollmentID_for_human_api', enrollmentId);
    }
    setCookie('journeyType', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('enrollmentID', null, {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    if (isAuthenticated || isTokenVerified) {
      return;
    }
    setCookie('bx_guest_ref', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
    setCookie(cookieName, '', {
      secure: true,
      expires: getCookieExpiryTime(0),
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    (async () => {
      const isOutcomePage = (route?.fields?.isOutcomePage as Field)?.value;
      if (isOutcomePage === true && isCDPScriptLoaded) {
        setCookie('journeyStatus', JOURNEY_STATUS.COMPLETED, {
          expires: getCookieExpiryTime(
            siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
          ),
          secure: true,
        });
        setCookie('journeyStep', '', {
          expires: getCookieExpiryTime(
            siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
          ),
          secure: true,
        });
        const potentialParticipantID =
          getCookie('bx_guest_ref') ||
          potentialParticipantDetailsFromStore?.potentialParticipant_ID;
        if (potentialParticipantID && !isJourneyStatusUpdated.current) {
          const potentialParticipantId = potentialParticipantID;
          const payload: any = {
            enrollmentId: getEnrollmentId(),
            journeyStep: '',
            journeyStatus: JOURNEY_STATUS.COMPLETED,
            isActiveEnrollment: '1',
            patientReferralStatus: router.asPath.includes('siteselectionsuccess')
              ? 'New'
              : cdpState?.patientReferralStatus,
          };
          payload.deActivateExistingEnrollment = true;
          const guestType = 'customer';
          await CdpService.updateCurrentJourneyInCDP(potentialParticipantId, guestType, payload);
          if (isSendEmailEnabled) {
            const emailExtensionData = {
              enrollmentId: getEnrollmentId() || '',
              emailType: cdpState?.emailType || '',
            };
            await CdpService.event(sendEmailEvent, emailExtensionData);
          }
          const contactInfoDetails = reduxState?.contactInfoDetails?.contactInfo;
          let mobileNumber = '';
          let isSmsConsented = 'No';

          if (
            contactInfoDetails &&
            (contactInfoDetails?.consentInfo || contactInfoDetails?.secondaryConsentInfo) &&
            contactInfoDetails?.patient
          ) {
            const { patient, delegate, consentInfo, secondaryConsentInfo } = contactInfoDetails;
            const dialingCode = getDialingCodeByISOCode(selectedLanguage?.countryISO2);
            mobileNumber =
              patient?.patientType === 'adult'
                ? `${dialingCode}${patient?.mobileNumber}`
                : `${dialingCode}${delegate?.mobileNumber}`;

            if (mobileNumber) {
              isSmsConsented =
                sourceIdentifier === 'EPR'
                  ? consentInfo?.isContactMediumSms
                  : sourceIdentifier === 'CR'
                  ? secondaryConsentInfo?.isSecondaryContactMediumSms
                  : 'No';
            }
          } else if (sitecoreContext?.CDP) {
            const {
              mobileNumber: cdpMobileNumber,
              isPrimarySmsOptedIn,
              isSecondarySmsOptedIn,
            } = sitecoreContext.CDP as any;
            mobileNumber = cdpMobileNumber;

            if (mobileNumber) {
              isSmsConsented =
                sourceIdentifier === 'EPR' ? isPrimarySmsOptedIn : isSecondarySmsOptedIn;
            }
          }
          //Remove this after testing
          console.log('sitecoreContext', sitecoreContext);
          console.log('cdpData', cdpState);
          if (isSendSmsEnabled && isSmsConsented === 'Yes' && mobileNumber) {
            const smsExtensionData = {
              enrollmentId: getEnrollmentId() || '',
              smsType: cdpState?.smsType || (sitecoreContext?.CDP as any)?.smsType || '',
              transactionalFromNumber: transactionalFromNumber || '',
              pinPointProjectId: pinPointProjectId || '',
              smsStudyName: smsStudyName || '',
              smsSponsor: smsSponsor || '',
              sourceIdentifier: sourceIdentifier || '',
              countryCode: countryCode || '',
              smsRootPath: smsRootPath || '',
              mobileNumber: mobileNumber || '',
            };
            sendWelcomeSms(smsExtensionData);
          }
          if (site?.name === ClinicalResearch) {
            //verify token
            const isTokenVerified = await verifyToken();
            resetCookies(isTokenVerified);
          } else {
            resetCookies(false);
          }
          setIsLoaded(true);
          isJourneyStatusUpdated.current = true;
        }
        if (!router.asPath.includes('siteselectionsuccess')) {
          updatePatientOutcomes(false);
        }
        if (router.asPath.includes('siteselectionsuccess')) {
          updatePatientOutcomes(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query, cdpState, isCDPScriptLoaded, cdpState?.emailType]);

  return isLoaded;
};

export default useUpdateCurrentJourney;
