import * as CdpService from '../../../services/CdpService';
export const sendWelcomeSms = async (smsExtensionData: any): Promise<boolean> => {
  try {
    const sendSmsEvent = process.env.NEXT_PUBLIC_CDP_SENDSMS_EVENT as string;
    await CdpService.event(sendSmsEvent, smsExtensionData);
    return true;
  } catch (error) {
    return false;
  }
};
