import { useEffect, useRef } from 'react';
import * as cdpService from '../../services/CdpService';
import { CDP_EVENTS, JOURNEY_STEP } from '../../constants';
import { post } from '../../lib/utils/axiosCDPFetcher';
import { JOURNEY_STATUS } from '../../constants';
import useJourneyModal from './useJourneyModal';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { JOURNEYS } from '../../constants';
import { Router, useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../store/store';
import { showLoader, hideLoader, setHomePageLoad } from '../../features/loaderSlice';
import { storeCDPData } from '../../features/cdpDataSlice';
import {
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getCookieExpiryTime,
  getJourneyTypeCookie,
  getOrCreateEnrollmentId,
} from '../utils/enrollmentUtil';
import { RootState } from '../../store/store';
import getDomainName from '../../lib/utils/getDomainName';
import { hasCookie } from 'cookies-next';
import { useMutation } from 'react-query';
import { post as postToDB } from '../../lib/utils/axiosFetcher';
import { getQuery, getQueryParams } from '../../lib/utils/common';
import { setCookie, getCookie } from 'cookies-next';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  initialState,
  removedQuaryParams,
  modifiedQuaryParams,
  addQuaryParams,
} from '../../features/queryParamsSlice';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import intersection from 'lodash/intersection';
import pick from 'lodash/pick';
import difference from 'lodash/difference';
import { updateGlobalSettings } from '../../features/globalConfig';

const removeSpaces = (input: string) => input.replace(/\s+/g, '');

const useCDPDataUpdateOnHomePageLoad = (
  isCDPScriptLoaded: boolean,
  context: any,
  router: any,
  triggerStartOver?: boolean
): any => {
  const routerLink = useRouter();
  const { sitecoreContext } = useSitecoreContext();
  const { route, siteSettings, resumeModal }: any = sitecoreContext;
  const siteName = sitecoreContext?.site?.name;
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const searchQueryData = useSelector((state: RootState) => state?.queryParams);
  const dispatch = useDispatch<AppDispatch>();
  const { query, locale, asPath, defaultLocale } = router;
  const cdpState = useSelector((state: RootState) => state.cdpData);
  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  const isHomePageLoaded = useSelector((state: RootState) => state.loader.isHomePageLoaded);
  const showResumePopupTimerId = useRef<any>(null);
  const potentialId = useSelector(
    (state: RootState) => state.potentialParticipantDetails.potentialId
  );
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );

  const scrollToScreener = () => {
    const itemOffset = document.getElementById('pre-screener-wrapper')?.offsetTop;
    const margin = 20;
    if (itemOffset) {
      window.scroll({
        top: itemOffset - margin,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const resetCookies = () => {
    setCookie('enrollmentID', null, {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStatus', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyStep', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    setCookie('journeyType', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    if (!isAuthenticated) {
      setCookie('bx_guest_ref', '', {
        expires: getCookieExpiryTime(0),
        secure: true,
      });
      const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
      setCookie(cookieName, '', {
        secure: true,
        expires: getCookieExpiryTime(0),
      });
    }
  };

  const updateCurrentJourneyStepinCDP = async () => {
    const payload = {
      enrollmentId: getOrCreateEnrollmentId(),
      journeyStep: getCookie('journeyStep', { secure: true }) as string,
      journeyStatus:
        (getCookie('journeyStatus', { secure: true }) as string) === JOURNEY_STATUS.COMPLETED
          ? JOURNEY_STATUS.COMPLETED
          : 'Terminated',
    };

    const { potentialParticipant_ID } = potentialParticipantDetailsFromStore;
    if (potentialParticipant_ID) {
      const guestType = cdpState?.guestType || 'visitor';
      await cdpService.updateCurrentJourneyInCDP(potentialParticipant_ID, guestType, payload);
    }
  };

  const updateParticipantStatusInDB = async () => {
    const payload = {
      potentialId: potentialParticipantDetailsFromStore?.potentialId,
      isActiveEnrollment: '0',
      journeyStatus:
        (getCookie('journeyStatus', { secure: true }) as string) === JOURNEY_STATUS.COMPLETED
          ? JOURNEY_STATUS.COMPLETED
          : 'Terminated',
    };
    return new Promise(async (resolve, reject) => {
      try {
        const { data } = await postToDB('/Participant/UpdateParticipantStatus', payload);

        resolve({
          data,
          payload: payload,
        });
      } catch (error) {
        reject({ error: error, payload: payload });
      }
    });
  };

  const { mutate: updateParticipantStatus } = useMutation(
    'update-participant',
    updateParticipantStatusInDB,
    {
      onSuccess: async () => {
        try {
          await updateCurrentJourneyStepinCDP();
          resetCookies();
          const afterRemovingResumeJourneyTokenQuery = omit(query, ['resumeJourneyToken']);
          const queryData = getQueryParams(afterRemovingResumeJourneyTokenQuery);
          const eprName = getCookie('epr-name');
          let hrefValue: string;
          if (queryData === '') {
            if (eprName) {
              hrefValue = locale === defaultLocale ? `/${eprName}` : `/${locale}/${eprName}`;
            } else {
              hrefValue = locale === defaultLocale ? `/` : `/${locale}`;
            }
          } else {
            if (eprName) {
              hrefValue =
                locale === defaultLocale
                  ? `/${eprName}?${queryData}`
                  : `/${locale}/${eprName}?${queryData}`;
            } else {
              hrefValue = locale === defaultLocale ? `/?${queryData}` : `/${locale}?${queryData}`;
            }
          }
          if (hrefValue.indexOf('//') === 0) {
            hrefValue = hrefValue?.replace('//', '/');
          }
          window.location.href = hrefValue;
        } catch (_e) {}
      },
      onError: () => undefined,
    }
  );

  const manageQueryParams = (params = {}) => {
    const url = (window as any)?.location?.origin + (window as any)?.location?.pathname;
    if (!isEmpty(params)) {
      routerLink.replace(
        {
          pathname: url,
          query: params,
        },
        url,
        { shallow: true }
      );
    }
  };

  const onStartOver = async () => {
    const queryParamsData = pickBy(getQuery(searchQueryData), (value) => !!value?.length);
    const diffKeys = difference(Object.keys(queryParamsData), Object.keys(initialState));
    const removableKeys = diffKeys.filter((a) => !query.hasOwnProperty(a));
    const queryObj = { ...initialState, ...query, removableKeys };
    dispatch(addQuaryParams(queryObj as any));
    dispatch(modifiedQuaryParams(queryObj as any));
    await updateParticipantStatus();
  };

  const manageCommonKeys = async () => {
    const queryParamsData = pickBy(getQuery(searchQueryData), (value) => !!value?.length);
    const commonKeys = intersection(Object.keys(queryParamsData), Object.keys(query));
    const commonObj = pick(queryParamsData, commonKeys);
    if (!isEmpty(commonObj)) {
      dispatch(removedQuaryParams(commonObj as any));
    }
  };

  const onResume = async () => {
    try {
      const journeyStep = getCookie('journeyStep') as string;
      const journeyId = getJourneyTypeCookie();
      const experienceId = cdpService.EXPERIENCE_ID_MAPPER[journeyStep];
      const data = await cdpService.fetchNextPossiblePathAndData(
        experienceId,
        false,
        false,
        sitecoreContext?.site?.name
      );
      const urlParams = data?.urlParams
        ? Object.assign(
            {},
            ...data.urlParams.map((p: { key: string; value: string }) => ({ [p.key]: p.value }))
          )
        : {};
      await manageCommonKeys();
      if (!isEmpty(urlParams)) dispatch(addQuaryParams(urlParams as any));
      dispatch(storeCDPData(data));
      if (data?.nextStepUrl && window.location.pathname !== data?.nextStepUrl) {
        if (data?.nextStepUrl === '/') {
          if (
            getCookie('epr-name') &&
            (journeyId === JOURNEYS.JOURNEY_01 || journeyId === JOURNEYS.JOURNEY_02)
          ) {
            setTimeout(() => {
              scrollToScreener();
            }, 2000);
          }
        } else {
          router.push(
            {
              pathname: data?.nextStepUrl,
              query: getQuery({ ...query, ...urlParams }),
            },
            data?.nextStepUrl
          );
        }
      } else if (
        (journeyId === JOURNEYS.JOURNEY_01 || journeyId === JOURNEYS.JOURNEY_02) &&
        data?.nextStepUrl === '/'
      ) {
        const queryParamsData = pickBy(getQuery(searchQueryData), (value) => !!value?.length);
        manageQueryParams(queryParamsData);
        setTimeout(() => {
          scrollToScreener();
        }, 2000);
      } else {
        router.push(data?.nextStepUrl);
      }
    } catch (_e) {}
  };

  const { setJourneyModal, showJourneyModal } = useJourneyModal({
    title: { value: resumeModal?.modalHeading?.value },
    description: {
      value: resumeModal?.modalDescription?.value,
    },
    customButtons: [
      { name: resumeModal?.startOverText?.value, callback: onStartOver },
      { name: resumeModal?.resumeText?.value, callback: onResume },
    ],
  });

  const showResumePopup = (isFromBookMark = false) => {
    const preferredLanguage = getCookie('language') || 'en';
    if (
      getCookie('journeyStatus') === JOURNEY_STATUS.PARTIAL &&
      getCookie('journeyStep') &&
      ((route?.fields?.isHomePage as any)?.value || isFromBookMark) &&
      preferredLanguage === locale
    ) {
      setJourneyModal();
      showJourneyModal();
    }
  };

  useEffect(() => {
    (async function () {
      if (triggerStartOver) {
        await onStartOver();
      }
    })();
  }, [triggerStartOver]);

  useEffect(() => {
    //HOME_PAGE_LOAD event, after cdpScript loaded and from home page
    if (isCDPScriptLoaded && route?.fields?.isHomePage) {
      const siteSettings: any = context?.siteSettings;
      const extensionData = {
        SiteName: context.site?.name,
        TenantKey: siteSettings?.tenantKey?.value as string,
      };
      cdpService.event(CDP_EVENTS.HOME_PAGE_LOAD, extensionData);
    }
  }, [isCDPScriptLoaded]);

  useEffect(() => {
    Router.events.on('routeChangeStart', () => dispatch(showLoader()));
    Router.events.on('routeChangeComplete', () => dispatch(hideLoader()));
    Router.events.on('routeChangeError', () => dispatch(hideLoader()));
  }, []);

  useEffect(() => {
    if (isEditorActive() || context?.pageState === 'preview') {
      return;
    }
    if (!router.isReady && route?.fields?.isHomePage) {
      dispatch(showLoader());
    } else {
      dispatch(hideLoader());
    }
  }, [route?.fields?.isHomePage, router.isReady]);

  const handleShowResumePopup = () => {
    clearTimeout(showResumePopupTimerId.current);
    showResumePopupTimerId.current = setTimeout(() => {
      showResumePopup();
      dispatch(hideLoader());
    }, 4000);
  };

  useEffect(() => {
    //If CR home page, dont show resume popup
    if (siteName === 'ClinicalResearch' && window.location.pathname === '/') {
      return;
    }
    if (
      router.isReady &&
      router?.query?.resumeJourneyToken === undefined &&
      !siteSettings?.disableResumeJourney?.value
    ) {
      dispatch(showLoader());
      handleShowResumePopup();
    }
  }, [locale, router.isReady]);

  useEffect(() => {
    (async () => {
      if (route?.fields?.isHomePage) {
        dispatch(setHomePageLoad(true));
        if (
          router.isReady &&
          router?.query?.resumeJourneyToken === undefined &&
          !siteSettings?.disableResumeJourney?.value
        ) {
          handleShowResumePopup();
        }
      } else if (getCookie('human_api_click')) {
        return;
      } else if (!isHomePageLoaded) {
        // case when user is comming from bookmark
        const { adultSiteUrls, caregiverSiteUrls, minorSiteUrls, automaticRedirectionUrls } =
          siteSettings as any;
        const adultSiteUrlsList = createUrlPaths(adultSiteUrls?.fields);
        const minorSiteUrlsList = createUrlPaths(minorSiteUrls?.fields);
        const caregiverSiteUrlsList = createUrlPaths(caregiverSiteUrls?.fields);
        const automaticRedirectionUrlsList = createAutomaticUrlPaths(automaticRedirectionUrls)?.map(
          (item: any) => item?.redirectionUrl
        );
        const directUrlsNotAllowed = [
          ...Object.values(adultSiteUrlsList),
          ...Object.values(minorSiteUrlsList),
          ...Object.values(caregiverSiteUrlsList),
          ...(automaticRedirectionUrlsList ?? []),
        ];
        if (directUrlsNotAllowed.indexOf(asPath || `/${locale}${asPath}`) > -1) {
          setTimeout(() => {
            router.replace('/');
          }, 500);
          //this code is not needed, will be problematic for TA EPR
          // setTimeout(() => {
          //   if (!siteSettings?.disableResumeJourney?.value) {
          //     showResumePopup(true);
          //   }
          // }, 2500);
        }
      }
    })();
  }, [route]);

  const createUrlPaths = (data: any) => {
    const res: { [key: string]: string } = {};
    for (const key in data) {
      res[key] = data[key]?.value?.href;
    }
    return res;
  };

  const createAutomaticUrlPaths = (automaticFailureUrls: any) => {
    const automaticFailureUrlsList =
      automaticFailureUrls &&
      automaticFailureUrls.map((urlObj: any) => {
        return {
          key: urlObj?.fields?.key?.value,
          redirectionUrl: urlObj?.fields?.redirectionUrl?.value?.href,
        };
      });
    return automaticFailureUrlsList;
  };

  const addGlobalSettingToCDP = async () => {
    const guestId = getCookie('bx_guest_ref');
    console.log('guestId', guestId);
    if (!guestId || !route?.fields?.isHomePage?.value) return;
    const {
      siteRootPath,
      successRootPath,
      smsRootPath,
      language,
      siteSettingsId,
      protocolSettingsId,
      siteSettings,
    } = context;
    const {
      maxNumberOfSites,
      adultSiteUrls,
      caregiverSiteUrls,
      minorSiteUrls,
      tenantKey,
      invitedProtocolQueryParamName,
      invitedSiteQueryParamName,
      dataControllerOrg,
      sendMailingList,
      baseUrl,
      enableScreenerLandingPage,
      studyName,
      source,
      automaticRedirectionUrls,
      returningUserTimeInterval,
      returningUserTimeValue,
      projectTherapeuticArea,
      diversityCohortParam,
      nonDiversityCohortParam,
    } = siteSettings as any;
    const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
    const browserId = getCookie(cookieName) as string;
    const queryParamsData = pickBy(getQuery(searchQueryData), (value) => !!value?.length);
    const urlParams =
      (!isEmpty(queryParamsData) &&
        Object.entries(queryParamsData).map(([key, value]) => ({ key, value }))) ||
      [];
    const globalDataSettingToCDP: any = {
      isActiveEnrollment: '1',
      potentialId: potentialId,
      tenantKey: tenantKey?.value,
      source: source?.fields?.name?.value,
      dataControllerOrg: dataControllerOrg?.fields?.name?.value,
      journeyStatus: JOURNEY_STATUS.PARTIAL,
      journeyStep:
        journeyType === JOURNEYS.JOURNEY_03 || siteSettings?.enableScreenerLandingPage?.value
          ? ''
          : JOURNEY_STEP.SCREENER,
      siteRootPath: siteRootPath,
      successRootPath: successRootPath,
      smsRootPath: smsRootPath,
      language: language,
      invitedSite:
        (invitedSiteQueryParamName?.value && query[invitedSiteQueryParamName?.value]) || '',
      invitedProtocol:
        (invitedProtocolQueryParamName?.value && query[invitedProtocolQueryParamName?.value]) || '',
      siteSettingsId: siteSettingsId,
      sendMailingList: sendMailingList?.value,
      baseUrl: baseUrl?.value,
      studyName: studyName?.value,
      protocolSettingsId: protocolSettingsId,
      enableScreenerLandingPage: enableScreenerLandingPage?.value,
      siteCount: maxNumberOfSites?.value,
      returningUserTimeInterval: returningUserTimeInterval?.value,
      returningUserTimeValue: returningUserTimeValue?.value,
      journeyId: journeyType,
      //createUrlPaths function will generate key-value pair where value will be href
      adultSiteUrls: createUrlPaths(adultSiteUrls?.fields),
      minorSiteUrls: createUrlPaths(minorSiteUrls?.fields),
      caregiverSiteUrls: createUrlPaths(caregiverSiteUrls?.fields),
      automaticRedirectionUrls: createAutomaticUrlPaths(automaticRedirectionUrls),
      isKiElementsSponsorConsentEnabled: siteSettings?.enableSponsorConsentKiElement?.value,
      isLifeLinkSponsorConsentEnabled: siteSettings?.enableSponsorConsentforLifeLink?.value,
      projectTherapeuticArea: projectTherapeuticArea?.fields?.key?.value,
      browserId,
      diversityCohortParam: diversityCohortParam?.value,
      nonDiversityCohortParam: nonDiversityCohortParam?.value,
      urlParams,
      eprName: getCookie('epr-name'),
    };
    const cookieCategory = context?.cookiePreference?.cookieCategory;
    if (!hasCookie('AcceptCookie') && Array.isArray(cookieCategory) && cookieCategory.length > 0) {
      const cookieCategoryToSend: any[] = [];
      cookieCategory.forEach((item) => {
        const cookieCategoryName = item.fields?.name?.value;
        if (typeof cookieCategoryName === 'string') {
          const cookieCategoryItem = {
            categoryType: cookieCategoryName,
            isActive: true,
            isOptin: removeSpaces(cookieCategoryName).toLowerCase() === 'essentialcookies',
          };
          cookieCategoryToSend.push(cookieCategoryItem);
        }
      });
      const cookieTracker = {
        domain: getDomainName(),
        cookieCategory: cookieCategoryToSend,
      };
      globalDataSettingToCDP.cookieTracker = cookieTracker;
    } else if (hasCookie('AcceptCookieSettings')) {
      const acceptCookieSettings = JSON.parse(getCookie('AcceptCookieSettings') as string);
      if (acceptCookieSettings) {
        const cookieTracker = acceptCookieSettings;
        globalDataSettingToCDP.cookieTracker = cookieTracker;
      }
    }

    try {
      await post(
        `/api/contacts/AddGlobalSettingsToDataExtension/${guestId}?guestType=visitor`,
        globalDataSettingToCDP
      );
      if (!getCookie('journeyStatus') || getCookie('journeyStatus') === JOURNEY_STATUS.COMPLETED) {
        //This is for first time user storing "journeyStatus" value in cookie
        setCookie('journeyStatus', JOURNEY_STATUS.PARTIAL, {
          expires: getCookieExpiryTime(
            siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
          ),
          secure: true,
        });
        setCookie('tenantKey', siteSettings?.tenantKey?.value, {
          expires: getCookieExpiryTime(
            siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
          ),
          secure: true,
        });
      }
      dispatch(updateGlobalSettings(true));
      manageQueryParams(queryParamsData);
    } catch (_e: any) {}
  };

  // useEffect(() => {
  //   if (route?.fields?.isHomePage && getCookie('journeyStatus') === JOURNEY_STATUS.COMPLETED) {
  //     setCookie('enrollmentID', null, {
  //       expires: getCookieExpiryTime(0),
  //     });
  //     setCookie('journeyType', '', {
  //       expires: getCookieExpiryTime(0),
  //       secure: true,
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (
      isCDPScriptLoaded &&
      potentialId !== '' &&
      (getCookie('journeyStatus') === '' ||
        getCookie('journeyStatus') === undefined ||
        getCookie('journeyStatus') === JOURNEY_STATUS.COMPLETED)
    ) {
      if (getCookie('bx_guest_ref')) {
        addGlobalSettingToCDP();
      } else {
        setTimeout(addGlobalSettingToCDP, 1500);
      }
    }
  }, [query, isCDPScriptLoaded, potentialId]);
};

export default useCDPDataUpdateOnHomePageLoad;
